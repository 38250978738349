<template>
  <span>
    <v-row dense justify="center" class="mt-8">
      <v-col cols="auto">
        <h2>
          {{
            traducaoIndicadores[indicadorSelecionado] ||
              indicadorSelecionado.toUpperCase()
          }}
        </h2>
      </v-col>
    </v-row>
    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="dados in dadosIndicadores"
        :key="dados.empresa.sigla_oficial"
        cols="12"
        md="6"
      >
        <v-card class="my-1">
          <v-card-title>
            <h5>
              {{ dados.empresa.name }}
            </h5>
          </v-card-title>
          <v-card-text>
            <column-chart-mensal
              :dadosMensais="dados.indicadores"
              :categories="categories"
              :indicador="indicadorSelecionado"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  export default {
    name: "LayoutVisaoGeral",
    components: {
      ColumnChartMensal: () =>
        import(
          "@/components/dashboardNivelTensao/charts/ColumnChartMensal.vue"
        ),
    },
    data: () => ({
      //
    }),
    props: {
      indicadorSelecionado: {
        type: String,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      dadosIndicadores: {
        type: Object,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
    },
  };
</script>
